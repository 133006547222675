import { BOOKING_URLS, buildURLWithParams, CATALOG_URLS, Types } from 'common';
import { getIsSyfatCourse } from '../../../OnDemandCourse/course.utils';

type GenerateBookingUrlProps = {
    id: string;
    isMember: boolean;
    contentType: Types.ContentType;
    brand: Types.ProductBrand | null | undefined;
    externalId: string;
    contentId: string;
    locale: Locale;
};

export const generateBookingUrl = ({
    id,
    isMember,
    contentType,
    brand,
    externalId,
    contentId,
    locale
}: GenerateBookingUrlProps): string => {
    if (
        contentType === Types.ContentType.OnDemandAccredited ||
        brand === Types.ProductBrand.Syfat
    ) {
        if (locale === 'en-GB') {
            const eventUrl = buildURLWithParams(BOOKING_URLS.event, [id]);
            const eventUrlWithType = `${eventUrl}?eventType=onDemandAccredited`;
            return eventUrlWithType;
        }

        if (isMember) {
            return buildURLWithParams(CATALOG_URLS.onDemandAccreditationCourse, [id]);
        }
        const isSyfatCourse = getIsSyfatCourse(brand);
        return `${BOOKING_URLS.memberships}${isSyfatCourse ? `?contentId=${contentId}` : ''}`;
    }

    const eventUrl = buildURLWithParams(BOOKING_URLS.event, [externalId]);
    return eventUrl;
};
