import { Types } from 'common';
import { ContentUnitItem } from '../../components/ContentUnit/ContentUnit.types';
import type { OnDemandCourseByIdQuery } from '../../graphql/catalog/queries';

export const isOnDemandAccreditedModuleTestCompleted = (
    courseModules: Array<{
        id: string;
        certificate?: { reportStatus?: Types.ReportStatus } | null;
    }>,
    moduleId: string | undefined
) => {
    const moduleCertificateReportStatus = courseModules?.find((m) => m.id === moduleId)?.certificate
        ?.reportStatus;
    const isTestCompleted =
        moduleCertificateReportStatus != null &&
        (
            [
                Types.ReportStatus.RequiresTopup,
                Types.ReportStatus.Generating,
                Types.ReportStatus.Generated
            ] as Array<Types.ReportStatus>
        ).includes(moduleCertificateReportStatus);
    return isTestCompleted;
};

export const isAnyVideoAlreadyStartedOrFinished = (
    videos: Array<{ contentProgress?: { time?: number | null; completed?: boolean | null } | null }>
): boolean => {
    return Boolean(
        videos.find(
            (v) =>
                (v.contentProgress?.time && v.contentProgress.time > 0) ||
                v.contentProgress?.completed
        )
    );
};

export const startedWatchingAllVideosInModule = (
    videos: Array<{
        contentProgress?: {
            time?: number | null;
            completed?: boolean | null;
        } | null;
        module?: { id: Types.UUID } | null;
    }>,
    moduleId: Types.UUID | undefined
): boolean => {
    return videos
        .filter((v) => v.module?.id === moduleId)
        .every(
            (v) =>
                v.contentProgress?.completed ||
                (v.contentProgress?.time && v.contentProgress.time > 0)
        );
};

export const isOnDemandAccreditedModuleCompletelyWatched = (
    courseVideos: Array<{
        id: Types.UUID;
        module?: { id: string } | null;
        contentProgress?: { completed?: boolean | null | undefined } | null;
        source?: {
            duration?: number | null | undefined;
        } | null;
    }>,
    moduleId: string | undefined
): {
    isUnitCompleted: boolean;
    moduleVideos: Array<ContentUnitItem>;
} => {
    const moduleVideos = courseVideos
        .filter((v) => v.module?.id === moduleId)
        .map((video) => ({ ...video, duration: video.source?.duration }));
    const isUnitCompleted =
        moduleVideos.length > 0 && moduleVideos.every((v) => v.contentProgress?.completed);
    return { isUnitCompleted, moduleVideos };
};

export const getIsSyfatCourse = (brand: Types.ProductBrand | null | undefined) =>
    brand === Types.ProductBrand.Syfat;

type CheckIfCourseIsCompletedProps = {
    videos: Array<Pick<OnDemandCourseByIdQuery['course']['videos'][number], 'contentProgress'>>;
};

/**
 * Check if all videos in a course are completed.
 */
export const checkIfCourseIsCompleted = ({ videos }: CheckIfCourseIsCompletedProps) =>
    videos.every((v) => v.contentProgress?.completed);
